<template>
  <div v-if="property_val" class="flex flex-col gap-4">
<!--      {{property_val}}-->
    <div v-for="(item_property, index_property) in property_val.properties"
         v-if="checkBanknotes(item_property, $parent)" :class="[ errors && errors.get(item_property.id) ? 'dirty valid' : '']">
      <div class="flex justify-between mb-1">
        <div class="header_option" :class="[ errors && errors.get(item_property.id) ? 'dirty valid' : '']">{{ $root.getLangText(item_property.title) }}<span v-if="item_property.is_required" class="text-red-500">*</span></div>
        <div class="header_option" v-html="$root.showPrice(item_property.price)"></div>
      </div>

      <!-- radio button -->
      <div class="options mt-2" v-if="item_property.ptype_id == 'select' && item_property.pcode_id !== 'building'">
        <div class="inline-block wrapper_select relative w-full"  v-if="item_property.extra !== undefined && item_property.extra && item_property.extra.hasOwnProperty('is_dropdown') && item_property.extra.is_dropdown == 'true' ">
          <select v-model="item_property.value" class="default_select  appearance-none" v-on:change="setValue(item_property.value, item_property.id)">
            <option v-for="(option, index) in item_property.attrs" :value="option.id" :key="option.id" v-text="$root.getLangText(option.title)"></option>
          </select>
        </div>
        <div v-else class="option mb-5">
          <div class="flex flex-col justify-between content_option" v-for="(option, index) in item_property.attrs" :key="index">
            <div>
              <input type="radio" class="default_radio" :name="'property-attr-' + item_property.id" :id="'property-attr-' + option.id" v-model="item_property.value" v-on:change="setValue(item_property.value, item_property.id)" :value="option.id">
              <label :for="'property-attr-' + option.id" class="flex justify-between">
                <div class="flex justify-between items-center w-full py-2" :class="[index == item_property.attrs.length - 1 ? '' : 'border-b']">
                  <div class="text-black font-light leading-snug">
                    <span class="mr-2">{{ $root.getLangText(option.title) }}</span>
                    <span class="text-gray-500 whitespace-nowrap"><span v-if="item_property.price_type_id == 'plus' && option.price > 0">+</span><span class="text-gray-500" v-html="$root.showPrice(option.price)"></span></span>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="options" v-if="item_property.ptype_id == 'select' && item_property.pcode_id == 'building'">
        <div class="option mb-5">
          <div class="inline-block relative w-full">
            <select v-model="item_property.value" v-on:change="setValue(item_property.value, item_property.id)" class="block appearance-none w-full bg-white border border-gray-200 hover:border-gray-300 px-4 py-2 pr-8 text-sm rounded leading-tight focus:outline-none focus:shadow-outline">
              <option v-for="(option, index) in item_property.attrs" :value="option.id" :key="option.id" v-text="$root.getLangText(option.title)"></option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
        </div>
      </div>

      <!-- checkbox -->
      <div class="options mt-2" v-if="item_property.ptype_id == 'checkbox'">
        <div class="option mb-5">
          <div class="flex flex-col justify-between content_option" v-for="(option, index) in item_property.attrs" :key="index">
            <div>
              <input
                  class="inp-cbx"
                  :id="'property-attr-' + option.id + index"
                  type="checkbox"
                  v-model="option.checked"
                  style="display: none;"
                  :disabled="disabledCheckbox(item_property, option)"
                  @change="checkboxChange(index_property)"/>
              <label class="cbx flex gap-4 items-center mx-auto select-none" :for="'property-attr-' + option.id + index">
                <span class="flex-shrink-0 w-4 h-4 flex items-center justify-center relative rounded border border-black">
                  <svg width="12px" height="10px" viewbox="0 0 12 10"><polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg>
                </span>
                <div class="flex justify-between items-center w-full py-2" :class="[index == item_property.attrs.length - 1 ? '' : 'border-b']">
                  <div class="text-black font-light leading-snug">
                    <span class="mr-2">{{ $root.getLangText(option.title) }}</span>
                    <span class="text-gray-500 whitespace-nowrap"><span v-if="item_property.price_type_id == 'plus' && option.price > 0">+</span><span class="text-gray-500" v-html="$root.showPrice(option.price)"></span></span>
                  </div>
                  <div v-if="option.checked && item_property.pcode_id == 'quantity'">
                    <v-input-quantity :disabled="disabledCheckboxInputQuantity(item_property)" :value.sync="option.quantity" :min="option.minimum == null || option.minimum === 0 ? 1 : option.minimum" :max="option.maximum" :template="'small'"></v-input-quantity>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="options" v-if="item_property.ptype_id == 'datetime' && item_property.pcode_id == 'scheduled'">
        <div class="option mb-5">
          <div class="form_group mt-2 mb-5">
            <article-calendar-time-picker :product-uuid="productUuid" :value.sync="item_property.value" :max-booking-slot="maxBookingSlot" />
          </div>
        </div>
      </div>

      <div class="form_group">
        <div v-if="item_property.ptype_id == 'text' && item_property.pcode_id !== 'phone_number'">
          <textarea v-if="item_property.extra && item_property.extra.hasOwnProperty('is_resizable') && item_property.extra.is_resizable"
                    v-model="item_property.value" v-on:change="setValue(item_property.value, item_property.id)">
          </textarea>
          <input v-else type="text" class="h-4" v-model="item_property.value" v-on:change="setValue(item_property.value, item_property.id)">
        </div>
      </div>

      <div v-if="item_property.ptype_id == 'text' && item_property.pcode_id === 'phone_number'">
        <app-input-phone-number :phone-number.sync="item_property.value" :property-id="item_property.id"></app-input-phone-number>
      </div>

      <div class="inline-block relative w-full" v-if="item_property.ptype_id == 'time'">
        <article-time-picker ref="timePicker" :item-property.sync="item_property" :properties="property.properties"></article-time-picker>
      </div>

      <div>
        <div v-if="item_property.ptype_id == 'date'">
          <article-date-picker :item-property.sync="item_property"></article-date-picker>
        </div>
      </div>

      <div class="form_group">
        <div v-if="item_property.ptype_id == 'calendar'" class="mt-2">
          <app-miltiple-date-picker v-on:updateDates="updateDates($event, index_property)" ></app-miltiple-date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
/*
 * doc https://air-datepicker.com/ru/docs
 */
import ArticleDatePicker from './ArticleDatePicker';
import ArticleTimePicker from './ArticleTimePicker';
import AppMiltipleDatePicker from '@/components/article/AppMiltipleDatePicker';
import ArticleCalendarTimePicker from './ArticleCalendarTimePicker.vue';
import AppInputPhoneNumber from "@/components/article/AppInputPhoneNumber";



export default {
  name: 'app_properties',
  components: {
    ArticleDatePicker,
    ArticleTimePicker,
    AppMiltipleDatePicker,
    ArticleCalendarTimePicker,
    AppInputPhoneNumber
  },

  props: {
    property: null,
    errors: null,
    productId: null,
    productUuid: null,
    maxBookingSlot: null,
    quantity: null,
    schedule: null,
  },

  data() {
    return {
      pikadayOptions: {
        minDate : moment().toDate(),
      },
      lang: 'en',
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.lang = this.$root.currentLanguage == 'ru' ? 'ru' : 'en';
      // this.getLocalStorageAndSetUrlProperties();

      // this.fetchPerson();
    });
  },

  methods: {
      setValue(value, id) {
          this.$root.setPropertyToLocalStorage(id, value);
      },
    checkBanknotes(item_property,parent){
      return item_property.pcode_id !== 'banknotes' || (item_property.pcode_id === 'banknotes' && this.$root.hotel.payments && parent.hotelPayments && parent.selectedPayment && item_property?.extra?.hotel_payment_type_ids.includes(parent.selectedPayment.id))
    },
    checkboxChange(property_index) {
      this.property_val.properties[property_index].value = this.property_val.properties[property_index].attrs.filter(one => one.checked).length == 0
          ? null
          : 'checked';

      this.$root.setPropertyToLocalStorage(this.property_val.properties[property_index].id, this.property_val.properties[property_index].value)
    },

    disabledCheckbox(item_property, option) {
      // checking the number of selected items must be less than the maximum property, otherwise the system will not checked
      if (this.disabledCheckboxInputQuantity(item_property) && !option.checked)
        return true;

      return item_property.max_checked_attrs_count !== null &&
          item_property.max_checked_attrs_count > 0 &&
          item_property.attrs.filter(item => item.checked).length >= item_property.max_checked_attrs_count
          && !option.checked
    },

    disabledCheckboxInputQuantity(item_property) {
      var total_quantity_selected_items = item_property.attrs.filter(item => item.checked)
          .reduce((prev, item) => {
            return prev + item.quantity
          }, 0);

      if (item_property.maximum !== null && total_quantity_selected_items && total_quantity_selected_items >= item_property.maximum)
        return true;

      return false;
    },

    updateDates(dates, property_index){
      this.property_val.properties[property_index].value = dates;
    },

    validate() {
      this.errors.errors = [];

      if (this.property_val == undefined || this.property_val == null)
        return true;

      if (this.property_val.properties === undefined || this.property_val.properties == null)
        return true;

      if (this.property_val.properties !== undefined && Object.keys(this.property_val.properties).length == 0) {
        return true;
      }

      return  Object.keys(this.property_val.properties).length > 0 && this.property_val.properties
          .filter(item => !this.isValidProperty(item))
          .length === 0;
    },

    isValidProperty(property) {
      if (!property.is_required)
        return true;

      if(property.pcode_id == 'banknotes' && this.$parent.selectedPayment.ptype_id !== 'cash'){
        return true;
      }

      if (property.value === '' || property.value === null) {
        this.errors.errors[property.id] = 'invalid';
        return false;
      }

      if (property.pcode_id == 'phone_number' && property.value.replace(" ", "").replace("-", "").length < 10) {
        return false;
      }

      return true;
    },

    getLocalStorageAndSetUrlProperties(property) {
      try {
        if (localStorage.getItem('property_values') == undefined) return property;

        let data = JSON.parse(localStorage.getItem('property_values'));

        if (data.length === 0) return;

        if (property == undefined || property == null)
          return true;

        if (property.properties === undefined || property.properties == null)
          return true;

        if (property.properties !== undefined && Object.keys(property.properties).length == 0)
          return true;
        data.map(dataItem => {
          if (this.$root.hotel.site === 'hadassah.2roomz.com' || this.$root.hotel.site == 'hadassah-hospital.2roomz.com') {
              property.properties.map((item, index) => {
              if (item.extra.old_id == dataItem.id) {
                  property.properties[index].value = dataItem.value;
              }
            });

              property.properties.map((item, index) => {
              if (item.id == dataItem.id) {
                  property.properties[index].value = dataItem.value;
              }
            });
          } else {
              property.properties.map((item, index) => {
              if (item.id == dataItem.id) {
                  if (item.ptype_id === 'checkbox') {
                      let val = item.attrs.length > 0 ? item.attrs[0] : null;
                      if (val) {
                          val.checked = (dataItem.value === 'checked' ? true : false);
                          property.properties[index].attrs[0] = val;
                      }
                  }
                  else {
                    property.properties[index].value = dataItem.value;
                  }
              }
            });
          }
        });
        return property;
      } catch (e) {
        console.error(e);
          return property;
      }
    },


    fetchPerson(property) {
      try {
        var person_uuid =  (localStorage.getItem('person-uuid') != undefined)
            ? localStorage.getItem('person-uuid')
            : null;

        if (!person_uuid)
          return property;

        var url = process.env.VUE_APP_API_URL + 'public/hotels/' + this.$root.hotel.uuid + '/guests/' + person_uuid;
        var result = this.$http.get(url);


        var person = result.data.data;
          property.properties.map((item, index) => {
          if (item.ptype_id == 'text' && item.pcode_id == 'name' && person.name) {
              property.properties[index].value = person.name;
          }

          if (item.ptype_id == 'text' && item.pcode_id == 'phone_number' && person.idphone) {
              property.properties[index].value = person.idphone;
          }
        });

          return property;
      } catch (e) {
        console.error(e);
          return property;
      }

    },
  },
    computed: {
        property_val: {
            get() {
                let property = this.property;

                property = this.getLocalStorageAndSetUrlProperties(property);

                this.fetchPerson(property);
                return property;
            },
            set(val) {
                this.$emit('update:property', val)
            }
        },
    }

}
</script>

<style>
.mx-datepicker {
  width: 100% !important;
}
.mx-input {
  height: 40px !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: 1px solid rgb(229 231 235) !important;
}
.mx-calendar-content .cell.active {
  background-color: var(--main-color) !important;
}
</style>